<script setup>
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router"

import { canAddSite, canEditProject, getProjectByCmiId } from "@/services/Project"
import { setCurrentViewContext } from "@/utils/GlobalState"
import PanelLabelValuePair from "@/components/sites/PanelLabelValuePair.vue"
import ProjectHomeAccountListPanel from "@/components/sites/ProjectHomeAccountListPanel.vue"
import ProjectHomeSiteListPanel from "@/components/sites/ProjectHomeSiteListPanel.vue"
import IconPencil from "@/components/svg/IconPencil.vue"
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext
const haveResults = ref(false)
const route = useRoute()
const router = useRouter()
const project = ref(null)
const projectCmiId = parseInt(route.params.projectCmiId)
const active = ref("details")

async function getData() {
  project.value = await getProjectByCmiId(projectCmiId)
  if (!project.value) {
    router.replace({ name: "SiteList" })
    return
  }
  setCurrentViewContext(project, "project")
  haveResults.value = true
}

getData()
</script>

<template>
  <article>
    <h1 class="title">
      {{ $gettext("Project Home") }}
    </h1>
    <router-link
      :to="{ name: 'ProjectLevelDemographicReport', params: { projectCmiId } }"
      class="button is-fullwidth mb-3"
    >
      {{ $gettext("Project reports") }}
    </router-link>

    <section
      v-if="haveResults"
      class="panel is-primary"
    >
      <p class="panel-tabs">
        <a
          :class="{ 'is-active': active == 'details' }"
          @click="active = 'details'"
        >
          {{ $gettext("Details") }}
        </a>
        <a
          :class="{ 'is-active': active == 'sites' }"
          @click="active = 'sites'"
        >
          {{ $gettext("Sites") }}
        </a>
        <a
          v-if="canEditProject(project)"
          :class="{ 'is-active': active == 'accounts' }"
          @click="active = 'accounts'"
        >
          {{ $gettext("Accounts") }}
        </a>
      </p>

      <!-- Details Tab -->
      <template v-if="haveResults && active == 'details'">
        <section class="is-flex-direction-column">
          <PanelLabelValuePair
            :label="$gettext('Name')"
            :value="project.name"
          />
          <PanelLabelValuePair
            :label="$gettext('Country')"
            :value="project.country"
          />
          <div class="panel-block">
            <router-link
              v-if="canAddSite(project)"
              class="button is-fullwidth is-medium is-responsive is-primary"
              :to="{ name: 'ProjectUpdate', params: { projectCmiId } }"
            >
              <IconPencil />
              {{ $gettext("Edit") }}
            </router-link>
          </div>
        </section>
      </template>

      <!-- Sites Tab -->
      <ProjectHomeSiteListPanel
        v-if="active === 'sites'"
        :project-cmi-id="projectCmiId"
      />

      <!-- Accounts Tab -->
      <ProjectHomeAccountListPanel
        v-if="active === 'accounts'"
        :project-cmi-id="projectCmiId"
      />
    </section>
  </article>
</template>
<style scoped>
.site-list h2.subtitle {
  text-transform: none;
  font-weight: bold;
}
.site-list .buttons {
  margin-top: 0.5rem;
}
.site-list .panel-block {
  align-items: normal;
}
</style>
